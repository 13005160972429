<template>
  <v-footer
    app
    inset
    class="justify-center white py-1"
    style="font-size: 0.875rem; color: #343843;"
  >
    <span>Copyright &copy; ease-x.com</span>
  </v-footer>
</template>

<script>
export default {
  name: 'EaseFooter'
}
</script>

<style lang="scss" scoped></style>
