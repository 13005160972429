<template>
  <div class="portal">
    <ease-app-bar
      :actions="actions"
      :background-style="backgroundStyle"
    />

    <!-- 放入portal下children的component -->
    <v-main
      class="portal-main"
      style="height: 100%;"
    >
      <router-view />
    </v-main>

    <!-- footer -->
    <EaseFooter />
  </div>
</template>

<script>
import EaseAppBar from '@/components/bar/EaseAppBar'
import EaseFooter from '@/components/bar/EaseFooter'

export default {
  name: 'Portal',

  components: {
    EaseAppBar,
    EaseFooter
  },

  data: vm => ({
    backgroundStyle: {
      backgroundImage: 'linear-gradient(to right, #1596bb, #759d30)'
    }
  }),

  computed: {
    actions() {
      return [
        {
          text: this.$t('page.projects.title'),
          icon: 'mdi-format-list-bulleted-square',
          color: 'grey lighten-5',
          to: {
            name: 'Projects',
            query: this.$route.query
          }
        },
        {
          text: this.$t('page.teams.title'),
          icon: 'mdi-account-multiple',
          color: 'grey lighten-5',
          to: {
            name: 'Teams',
            query: this.$route.query
          }
        },
        {
          text: this.$t('page.files.title'),
          icon: 'mdi-folder-outline',
          color: 'grey lighten-5',
          to: {
            name: 'FileMgr',
            query: this.$route.query
          }
        },
        {
          text: this.$t('page.databases.title'),
          icon: 'mdi-database',
          color: 'grey lighten-5',
          to: {
            name: 'Databases',
            query: this.$route.query
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.portal {
  height: 100vh;

  .portal-main {
    ::v-deep .v-main__wrap {
      overflow-y: auto;

      .container {
        max-height: 100%;
      }
    }
  }
}
</style>
