<template>
  <v-app-bar
    class="ease-bar"
    app
    :style="backgroundStyle"
    :height="height"
  >
    <v-toolbar-title
      class="mx-sm-11 d-flex align-center"
      style="height: 100%;"
    >
      <v-img
        contain
        max-height="114%"
        src="@/assets/images/logo.svg"
        style="cursor: pointer;"
        @click="toHomePage"
      />
    </v-toolbar-title>
    <!-- <slot name="logo" :toHomePage="toHomePage" :attrs="{style:'cursor: pointer'}" /> -->
    <div
      v-for="action in actions"
      :key="`span-${action.text}`"
      class="d-none d-sm-block"
    >
      <v-btn
        v-if="action.attr"
        :key="`btn-${action.text}`"
        :color="action.color"
        text
        x-large
        @click="$emit('attr-change', action)"
      >
        <v-icon
          v-if="action.icon"
          left
        >
          {{ action.icon }}
        </v-icon>
        {{ action.text }}
      </v-btn>
      <v-btn
        v-else-if="action.to"
        :key="`btn-${action.text}`"
        :color="action.color"
        text
        x-large
        :to="action.to"
      >
        <v-icon
          v-if="action.icon"
          left
        >
          {{ action.icon }}
        </v-icon>
        {{ action.text }}
      </v-btn>
    </div>

    <v-spacer />

    <TranslationMenu>
      <template #activator="{ on, attrs }">
        <v-btn
          class="align-self-center d-none d-sm-inline-flex"
          v-bind="attrs"
          icon
          dark
          v-on="on"
        >
          <v-icon>
            mdi-translate
          </v-icon>
        </v-btn>
      </template>
    </TranslationMenu>

    <UserAvatar v-if="userName">
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          dark
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-account-circle
          </v-icon>
          {{ userName }}
        </v-btn>
      </template>
    </UserAvatar>
  </v-app-bar>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TranslationMenu from '@/components/TranslationMenu'

import { mapGetters } from 'vuex'

export default {
  name: 'EaseAppBar',

  components: {
    UserAvatar,
    TranslationMenu
  },

  props: {
    actions: {
      type: Array,
      default: () => []
    },
    backgroundStyle: {
      type: Object,
      default: () => {}
    },
    height: {
      type: [Number, String],
      default: undefined
    }
  },

  data: () => ({
    functions: {
      全地圖預覽: 'mdi-arrow-expand-all',
      截圖: 'mdi-camera-iris'
    }
  }),

  computed: {
    ...mapGetters({
      userName: 'user/userName'
    })
  },

  created() {
    if (!this.userName) {
      this.fetchUser()
    }
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('user/fetchUser')
    },
    toHomePage() {
      this.$router.push({
        name: 'Projects',
        params: this.$route.params,
        query: this.$route.query
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.ease-bar.v-app-bar {
  ::v-deep .v-toolbar__content {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
</style>
